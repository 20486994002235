import { useState } from "react";
import { Box, Button, Stack, Wrap, Input, Checkbox } from "@chakra-ui/react";
import GooseCollectionData from "../data/geese.json";
import { useEffect } from "react";
import Goose, { GooseProps } from "./Goose";

const GooseData = GooseCollectionData.collection as GooseProps[];

const GooseGrid = () => {
  const [itemsToShow, setItemsToShow] = useState(104);
  const [searchTerm, setSearchTerm] = useState("");
  const [shuffle, setShuffle] = useState(false);
  const [sub100k, setSub100k] = useState(false);
  const [rare, setRare] = useState(false);
  const [cursed, setCursed] = useState(false);
  const [filteredData, setFilteredData] = useState(GooseData as GooseProps[]);
  const [shuffledData, setShuffledData] = useState([] as GooseProps[]);

  useEffect(() => {
    // Filter data based on user inputs
    let filtered = GooseData.filter((goose: GooseProps) => {
      const isSub100k = !sub100k || (goose.sub100k && goose.id < 300);
      const isRare = !rare || goose.rare;
      const isCursed = !cursed || goose.cursed;

      return (
        ((searchTerm.length < 5 && goose.id.toString() === searchTerm) ||
          searchTerm.length >= 5 ||
          (searchTerm.length === 0 && goose.inscription.toLowerCase().includes(searchTerm.toLowerCase()))) &&
        isSub100k &&
        isRare &&
        isCursed
      );
    });

    // Shuffle data if needed
    if (shuffle) {
      filtered.sort(() => Math.random() - 0.5);
      setShuffledData(filtered);
      setFilteredData([]);
    } else {
      setFilteredData(filtered);
      setShuffledData([]);
    }
  }, [searchTerm, sub100k, rare, cursed, shuffle]);

  const handleLoadMoreClick = () => {
    setItemsToShow(itemsToShow + 104);
  };

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleShuffleCheck = () => {
    setShuffle(!shuffle);
    setItemsToShow(104);
  };

  const handleSub100kCheck = () => {
    setSub100k(!sub100k);
    setItemsToShow(104);
  };

  const handleRareCheck = () => {
    setRare(!rare);
    setItemsToShow(104);
  };

  const handleCursedCheck = () => {
    setCursed(!cursed);
    setItemsToShow(104);
  };

  const visibleItems = shuffle ? shuffledData.slice(0, itemsToShow) : filteredData.slice(0, itemsToShow);

  return (
    <>
      <Box
        width={"100%"}
        marginLeft="auto"
        marginRight="auto"
        marginTop={4}
        marginBottom={4}
        backgroundColor={"#fff"}
        color={"000"}
        paddingTop={4}
        paddingBottom={2}
        paddingLeft={4}
        paddingRight={4}
        borderRadius={10}
        style={{
          boxShadow: "0 4px 6px rgba(0,0,0,0.3), 0 1px 3px rgba(0,0,0,0.08)",
        }}
      >
        <Stack direction="row" align="center" mb={4}>
          <Input placeholder="Search by ID or Inscription" value={searchTerm} onChange={handleSearchInputChange} />
          <Checkbox isChecked={shuffle} onChange={handleShuffleCheck} color="#000">
            Shuffle
          </Checkbox>
          <Checkbox isChecked={sub100k} onChange={handleSub100kCheck} color="#000">
            Sub_100k
          </Checkbox>
          <Checkbox isChecked={rare} onChange={handleRareCheck} color="#000">
            Rare
          </Checkbox>
          <Checkbox isChecked={cursed} onChange={handleCursedCheck} color="#000">
            Cursed
          </Checkbox>
        </Stack>
      </Box>
      <Wrap justify="center">
        {visibleItems.map((goose: GooseProps) => {
          return <Goose {...goose} />;
        })}
      </Wrap>
      {itemsToShow < (shuffle ? shuffledData : filteredData).length && (
        <Button onClick={handleLoadMoreClick} mt={4} marginBottom={10}>
          Load More
        </Button>
      )}
    </>
  );
};

export default GooseGrid;
