import {
  Box,
  Flex,
  Text,
  Image,
  WrapItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Link,
  Badge,
  Wrap,
  InputGroup,
  Input,
  InputRightElement,
} from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";
import { useState } from "react";

export interface Attribute {
  trait_type: string;
  value: string;
}

export interface GooseProps {
  id: number;
  name: string;
  image: string;
  sub100k: boolean;
  rare: boolean;
  cursed: boolean;
  inscription: string;
  attributes: Attribute[];
}

const Goose = ({ id, name, image, sub100k, rare, cursed, inscription, attributes }: GooseProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleInscriptionClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <WrapItem
        style={{
          margin: 10,
          borderWidth: 8,
          borderColor: "#fff",
          borderRadius: 10,
          boxShadow: "0 4px 6px rgba(0,0,0,0.3), 0 1px 3px rgba(0,0,0,0.08)",
        }}
      >
        <Box
          position="relative"
          _hover={{
            "& > div": {
              opacity: 1,
            },
          }}
          onClick={handleInscriptionClick}
          cursor="pointer"
        >
          <Image src={`./geese/${image}`} width={110} height={110} style={{ imageRendering: "pixelated" }} />
          <Box
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height="100%"
            backgroundColor="rgba(0,0,0,0.1)"
            opacity={0}
            transition="opacity 0.2s ease-in-out"
          />
          <Box
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height="20%"
            backgroundColor="rgba(0,0,0,0.5)"
            opacity={0}
            transition="opacity 0.2s ease-in-out"
          >
            <Text color="white" fontSize="xs" fontWeight="bold" paddingTop={0.5}>
              #{id}
            </Text>
          </Box>
          <Box
            position="absolute"
            bottom={0}
            left={0}
            width="100%"
            height="20%"
            backgroundColor="rgba(0,0,0,0.5)"
            opacity={0}
            transition="opacity 0.2s ease-in-out"
          >
            <Text color="white" fontSize="xs" fontWeight="bold" paddingTop={0.5}>
              ORDS
            </Text>
          </Box>
        </Box>
      </WrapItem>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal} size={"2xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>#{id}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex marginBottom={3}>
              <Box w="150px">
                <Link href={`./geese_large/${id}.png`} isExternal>
                  <Image src={`./geese/${image}`} width={130} style={{ imageRendering: "pixelated" }} />
                </Link>
              </Box>
              <Box flex="1">
                {id < 37 || id > 1000 ? <Text>{name}</Text> : <Text>Goose ID: {id}</Text>}

                <Wrap direction="row" style={{ marginTop: 5, marginBottom: 5 }}>
                  {cursed && <Badge>Cursed</Badge>}
                  {rare && <Badge>Rare</Badge>}
                  {sub100k && id < 300 && <Badge>Sub 100k</Badge>}
                  {attributes.map((attribute) => {
                    if (attribute.trait_type === attribute.value) return <></>;

                    let colorScheme = "gray";
                    if (attribute.value === "Green") colorScheme = "green";
                    if (attribute.value === "Blue") colorScheme = "blue";
                    if (attribute.value === "Pink") colorScheme = "pink";
                    if (attribute.value === "Flamingo") colorScheme = "pink";
                    if (attribute.value === "Duckling") colorScheme = "yellow";
                    if (attribute.value === "Flames") colorScheme = "orange";
                    if (attribute.value === "Red") colorScheme = "red";
                    if (attribute.value === "Regular") colorScheme = "orange";
                    if (attribute.value === "Wizard Hat") colorScheme = "purple";
                    if (attribute.value === "Mallard") colorScheme = "green";
                    if (attribute.value === "Zombie") colorScheme = "green";

                    let variant = "subtle";
                    if (attribute.value === "Black") variant = "solid";
                    if (attribute.value === "Black Swan") variant = "solid";
                    if (attribute.value === "White") variant = "outline";

                    let traitType = attribute.trait_type;

                    if (attribute.trait_type === "Glasses") traitType = "";
                    if (attribute.trait_type === "Head") traitType = "";

                    return (
                      <Badge colorScheme={colorScheme} variant={variant}>
                        {attribute.value} {traitType}
                      </Badge>
                    );
                  })}
                </Wrap>

                <Text style={{ marginTop: 10 }}>Inscription ID: </Text>
                <InputGroup size="md">
                  <Input pr="4.5rem" type="text" placeholder="Inscription ID" value={inscription} />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      onClick={() => {
                        navigator.clipboard.writeText(inscription);
                      }}
                    >
                      <CopyIcon></CopyIcon>
                    </Button>
                  </InputRightElement>
                </InputGroup>

                <Link
                  href={`https://ordinals.com/inscription/${inscription}`}
                  color="teal.500"
                  isExternal
                  style={{ paddingTop: 10 }}
                >
                  View Inscription
                </Link>
              </Box>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Goose;
