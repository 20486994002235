import { ChakraProvider, Box, theme } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./containers/LandingPage";
import ExperimentPage from "./containers/ExperimentPage";
import MintPage from "./containers/MintPage";
import RunesPage from "./containers/RunesPage";

export const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <Box height="100%" width="fit-content" minW="100%">
        <Router>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/experiment" element={<ExperimentPage />} />
            <Route path="/mint" element={<MintPage />} />
            <Route path="/runes" element={<RunesPage />} />
          </Routes>
        </Router>
      </Box>
    </ChakraProvider>
  );
};
