import mempoolJS from "@mempool/mempool.js";

export interface MempoolData {
  fastestFee: number;
  halfHourFee: number;
  hourFee: number;
  economyFee: number;
  minimumFee: number;
}

export const getSlowfee = (mempoolData: MempoolData): number => {
  const slowFee = Math.max(
    Math.round(mempoolData.hourFee * 0.5),
    Math.round(mempoolData.minimumFee * 1.75)
  );

  return slowFee;
};

export const getMempoolData = async (): Promise<MempoolData> => {
  const mempoolUrl = "https://mempool.space/api/v1/fees/recommended";
  const response = await fetch(mempoolUrl);
  const mempoolData: MempoolData = await response.json();

  return mempoolData;
};

export const getBlockTipHeight = async () => {
  const { bitcoin: { blocks } } = mempoolJS({
    hostname: 'mempool.space'
  });

  const blocksTipHeight = await blocks.getBlocksTipHeight();

  return blocksTipHeight;
};
