import { useState, useEffect } from 'react';
import { MempoolData, getBlockTipHeight, getMempoolData, getSlowfee } from '../utils/mempool';

interface UseFetchMempoolDataReturn {
  mempoolData: MempoolData | null;
  slowFee: number | null;
  isLoading: boolean;
  error: Error | null;
  blockTipHeight: number | null;
  blocksUntilHalving: number | null;
  estimatedHalvingDate: Date | null;
}

const HALVING_BLOCK_HEIGHT = 840000;
const BLOCK_TIME_MINUTES = 10;

export const useFetchMempoolData = (): UseFetchMempoolDataReturn => {
  const [mempoolData, setMempoolData] = useState<MempoolData | null>(null);
  const [slowFee, setSlowFee] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const [blockTipHeight, setBlockTipHeight] = useState<number | null>(null);
  const [blocksUntilHalving, setBlocksUntilHalving] = useState<number | null>(null);
  const [estimatedHalvingDate, setEstimatedHalvingDate] = useState<Date | null>(null); // Add this line

  useEffect(() => {
    const fetchMempoolData = async () => {
      try {
        const data = await getMempoolData();
        const blockTipHeight = await getBlockTipHeight();
        setMempoolData(data);
        setSlowFee(getSlowfee(data));
        setBlockTipHeight(blockTipHeight);

        const blocksUntilHalving = HALVING_BLOCK_HEIGHT - blockTipHeight;
        setBlocksUntilHalving(blocksUntilHalving);

        // Calculate the estimated halving date
        const minutesUntilHalving = blocksUntilHalving * BLOCK_TIME_MINUTES;
        const estimatedHalvingTimestamp = new Date().getTime() + minutesUntilHalving * 60 * 1000;
        setEstimatedHalvingDate(new Date(estimatedHalvingTimestamp));
      } catch (e) {
        setError(e as Error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMempoolData();
    const intervalId = setInterval(fetchMempoolData, 20000); // Adjust the interval as needed

    return () => clearInterval(intervalId);
  }, []);

  return { mempoolData, slowFee, isLoading, error, blockTipHeight, blocksUntilHalving, estimatedHalvingDate }; // Add estimatedHalvingDate here
};
