import { useEffect, useState } from "react";
import { useOrdConnect, OrdConnectKit, useSend } from "@ordzaar/ord-connect";
import {
  Button,
  Flex,
  Box,
  Text,
  useColorModeValue,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepIcon,
  StepNumber,
  StepTitle,
  StepSeparator,
  Spacer,
  TableContainer,
  Table,
  Tbody,
  Tr,
  Td,
} from "@chakra-ui/react";
import { MempoolData, getMempoolData } from "../utils/mempool";
import { Value } from "@node-dlc/bitcoin";
import { getNetworkFeesToClaim } from "../utils/fees";
import { getPrice } from "../utils/price";
import { halvingSnapshot, WalletData } from "../data/halving_snapshot";
import axios from "axios";

const botAddresses = [
  "bc1pj9g6hca62qq9navwpwlz8jjnk2t0nvkl34582advkllnlhdwl94s3ya3ud", // og1
  "bc1pcnresvjzktr2lf7qshh0xf0mke8tqc32lxrd252vvlcp8vs43gaswvte09", // og2
  "bc1pkrkq3ydc8nslwrx8a4ppkl8xv033zw3ar3k49kkn5uvzzwvcp5us4l2dss", // og3
  "bc1pffhcxcnustp223le8altqf3c77kfvkh8kwsshmt7gcj8we276x6q758ak6", // og4
  "bc1pw4ks09ls9an9ac443z0h3v6pkwhpu0fu54mmepavst08rg6edzfq82zh7a", // og5
  "bc1pz4akktl9x3au3xfvezux0fkj8xssjh333pwr85h3kfvkmfaa93lq6w2ygn", // og6
  "bc1pcek27xgmgkmsnkxs9j3fdlhclcdh69nvexg40xe2pge2uqchapaqc22ept", // og7
  "bc1p9uq09the8ktnvkrjshzl6smf0nestu379k9fmsef36u36cmq4geskqhlum", // og8
];

const ClaimProcess = () => {
  const { address } = useOrdConnect();
  const [step, setStep] = useState(0);

  const isWalletConnected = address.payments || address.ordinals;
  const nextButtonBg = useColorModeValue("blue.500", "blue.200"); // Change color based on theme

  const [mempoolData, setMempoolData] = useState<MempoolData | null>(null);
  const [totalAmount, setTotalAmount] = useState(Value.zero());
  const [dollarAmount, setDollarAmount] = useState("");
  const { send, loading: sendLoading, error: sendError } = useSend();
  const [walletData, setWalletData] = useState<WalletData | null>(null);
  const [claimedTxId, setClaimedTxId] = useState<string | null>(null);

  // localStorage.removeItem("claimedTxid");

  useEffect(() => {
    const fetchMempoolData = async () => {
      const data = await getMempoolData();
      setMempoolData(data);
      const price = await getPrice();
      const _totalAmount = getNetworkFeesToClaim(data.fastestFee);
      setTotalAmount(_totalAmount);
      setDollarAmount((price * _totalAmount.bitcoin).toFixed(2));
    };

    fetchMempoolData();
  }, []);

  const handleNext = () => {
    if (isWalletConnected || step > 1) {
      setStep(step + 1);
    }
  };

  useEffect(() => {
    // Get txid from local storage
    const claimedTxid = localStorage.getItem("claimedTxid");

    // Check if txid exists
    if (claimedTxid) {
      // If txid exists, you can handle it here
      console.log("Transaction already claimed:", claimedTxid);
      setClaimedTxId(claimedTxid);
    }
  }, []);

  useEffect(() => {
    if (isWalletConnected) {
      const walletAddress = address.ordinals || address.payments;
      if (walletAddress && walletAddress in halvingSnapshot) {
        const walletData = halvingSnapshot[walletAddress];
        if (walletData) {
          setWalletData(walletData);
        }
      }
    }
  }, [isWalletConnected, address]);

  const handleClaim = async () => {
    const walletAddress = address.ordinals || address.payments;
    if (!walletAddress) {
      console.error("No wallet address found.");
      return;
    }

    const randomIndex = Math.floor(Math.random() * botAddresses.length);
    const randomBotAddress = botAddresses[randomIndex];

    try {
      // const txid = "c6660b02989fa23370915a0870b3e3301326990e350d47204e5dcfcee2956961";

      const txid = await send(randomBotAddress, Number(totalAmount.sats), mempoolData?.fastestFee || 300);
      console.log(txid);
      console.log("Transaction successful");

      if (txid) {
        axios.post("/api/register_and_verify_claim", {
          txId: txid, // The transaction ID you're claiming
          ordinalAddress: walletAddress, // The address of the wallet that's claiming the transaction
          paymentAddress: randomBotAddress, // The address that's paying the transaction fees
          amount: totalAmount.bitcoin, // The amount of Bitcoin being claimed
          feeRatePerVb: mempoolData?.fastestFee || 300, // The fee rate per virtual byte
          inscriptionCount: walletData?.inscription_count,
          botIndex: randomIndex + 1,
        });

        setClaimedTxId(txid);

        // Store txid in local storage
        localStorage.setItem("claimedTxid", txid);

        setStep(step + 1);
      } else {
        console.error("Transaction failed");
      }
    } catch (error) {
      console.error("Transaction failed", error);
    }
  };

  const steps = [
    { title: "Connect Wallet", description: "Contact Info" },
    { title: "Pay Claim Network Fees", description: "Date & Time" },
    { title: "Receive Tokens", description: "Select Rooms" },
  ];

  useEffect(() => {
    if (isWalletConnected && step < 1) {
      setStep(step + 1);
    }
  }, [isWalletConnected, step]);

  return (
    <Flex direction="column" width="full">
      {/* <Flex direction="row" align="center" justify="center"> */}
      <Stepper index={step}>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />
            </StepIndicator>

            <Box flexShrink="0">
              <StepTitle>{step.title}</StepTitle>
            </Box>

            <StepSeparator />
          </Step>
        ))}
      </Stepper>
      {/* </Flex> */}

      {claimedTxId ? (
        <>
          <Box p={4} shadow="md" borderWidth="1px" borderRadius="md" mb={4} mt={10}>
            <Text style={{ marginBottom: 20, marginTop: 20 }}>
              {" "}
              <a
                href="https://ordinals.com/rune/OG%E2%80%A2ORDINAL%E2%80%A2GEESE"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "underline" }}
              >
                OG•ORDINAL•GEESE
              </a>{" "}
              tokens claimed!
            </Text>

            <Text style={{ marginBottom: 20, marginTop: 20 }}>
              Claim Transaction:{" "}
              <a
                href={`https://mempool.space/tx/${claimedTxId}`}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "underline" }}
              >
                {claimedTxId}
              </a>
            </Text>

            <Text>You should see them in your wallet within 24 hours.</Text>
          </Box>
        </>
      ) : (
        <>
          <Box p={4} shadow="md" borderWidth="1px" borderRadius="md" mb={4} mt={10}>
            {step === 0 && (
              <>
                <Text style={{ marginBottom: 20, marginTop: 20 }}>
                  To Claim your{" "}
                  <a
                    href="https://ordinals.com/rune/OG%E2%80%A2ORDINAL%E2%80%A2GEESE"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "underline" }}
                  >
                    OG•ORDINAL•GEESE
                  </a>{" "}
                  rune tokens, connect your wallet
                </Text>

                <Spacer />

                {!isWalletConnected && <OrdConnectKit />}
              </>
            )}

            {step === 1 && (
              <>
                {walletData ? (
                  <>
                    {walletData.sent ? (
                      <>
                        <Text>
                          You've already claimed your{" "}
                          <a
                            href="https://ordinals.com/rune/OG%E2%80%A2ORDINAL%E2%80%A2GEESE"
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: "underline" }}
                          >
                            OG•ORDINAL•GEESE
                          </a>{" "}
                          tokens. Happy Honkening!
                        </Text>
                      </>
                    ) : (
                      <>
                        <Text style={{ marginBottom: 30 }}>
                          You are eligible to claim {walletData.inscription_count * 840} 🪿 tokens since you had{" "}
                          {walletData.inscription_count} geese at the time of the Halving Snapshot
                        </Text>
                        <TableContainer>
                          <Table variant="simple">
                            <Tbody>
                              <Tr>
                                <Td>Fee Rate</Td>
                                <Td isNumeric>{mempoolData?.fastestFee} sat/vB</Td>
                              </Tr>
                              <Tr>
                                <Td>Total Amount</Td>
                                <Td isNumeric>
                                  {totalAmount.bitcoin} BTC (${dollarAmount})
                                </Td>
                              </Tr>
                            </Tbody>
                          </Table>
                        </TableContainer>

                        {sendError && (
                          <Text color="red.500" style={{ marginTop: 20 }}>
                            Error: {sendError}
                          </Text>
                        )}

                        <Button
                          mt={4}
                          onClick={handleClaim}
                          colorScheme="white"
                          backgroundColor="#e27e36"
                          isDisabled={!isWalletConnected}
                          isLoading={sendLoading}
                          loadingText="Claiming Tokens..."
                        >
                          Claim Tokens
                        </Button>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Text>
                      You are ineligible to receive{" "}
                      <a
                        href="https://ordinals.com/rune/OG%E2%80%A2ORDINAL%E2%80%A2GEESE"
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "underline" }}
                      >
                        OG•ORDINAL•GEESE
                      </a>{" "}
                      since you did not own any geese during the 4th Halving Snapshot
                    </Text>

                    <Text style={{ marginTop: 20 }}>
                      It is still available to buy on secondary markets like{" "}
                      <a
                        href="https://unisat.io/runes/market?tick=OG%E2%80%A2ORDINAL%E2%80%A2GEESE"
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "underline" }}
                      >
                        Unisat
                      </a>{" "}
                      and{" "}
                      <a
                        href="https://magiceden.io/runes/OG%E2%80%A2ORDINAL%E2%80%A2GEESE"
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "underline" }}
                      >
                        Magic Eden
                      </a>
                    </Text>
                  </>
                )}
              </>
            )}

            {step === 2 && (
              <>
                <Text>Step 2: Pay Claim Fees</Text>
                {/* Placeholder for payment component */}
                <Button mt={4} onClick={handleNext} backgroundColor={nextButtonBg}>
                  Next
                </Button>
              </>
            )}

            {step === 3 && (
              <>
                <Text>Step 3: Receive Tokens</Text>
                {/* Placeholder for token receipt confirmation */}
                <Button mt={4} onClick={handleNext} backgroundColor={nextButtonBg}>
                  Finish
                </Button>
              </>
            )}
          </Box>
        </>
      )}
    </Flex>
  );
};

export default ClaimProcess;
