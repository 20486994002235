import React, { useEffect } from "react";
import { useSignMessage, useOrdConnect, OrdConnectKit } from "@ordzaar/ord-connect";
import { Button } from "@chakra-ui/react";
import { Verifier } from "bip322-js";
import TurboWalletClient from "../clients/TurboWalletClient"; // Import TurboWalletClient

const UserCollections = () => {
  const { signMsg, isLoading, error } = useSignMessage();
  const { address } = useOrdConnect(); // Assuming `address` indicates connection status

  useEffect(() => {
    const turboWalletClient = new TurboWalletClient();

    const fetchWalletDetails = async () => {
      const walletAddress = address.ordinals || address.payments;
      if (walletAddress) {
        try {
          const walletDetails = await turboWalletClient.getWalletDetails(walletAddress);
          console.log(walletDetails);
        } catch (error) {
          console.error("Failed to fetch wallet details:", error);
        }
      }
    };

    fetchWalletDetails();
  }, [address]); // Re-fetch when address changes

  const handleSignMessage = async () => {
    // Assuming `address.payments` or `address.ordinals` can be used to sign the message
    // Adjust according to the actual API
    const walletAddress = address.ordinals || address.payments;
    if (!walletAddress) {
      console.error("No wallet address found.");
      return;
    }
    const signedMessage = await signMsg(walletAddress, "hello");
    console.log(signedMessage);

    if (signedMessage) {
      const validity = Verifier.verifySignature(walletAddress, "hello", signedMessage);
      console.log("signature valid: ", validity); // True
    }
  };

  // Only render the button if there's a connected wallet address
  if (!address.payments && !address.ordinals) {
    return <OrdConnectKit />;
  }

  return (
    <>
      <Button onClick={handleSignMessage} isLoading={isLoading} disabled={isLoading}>
        Sign Message
      </Button>
      {error && <p>Error: {error}</p>}
    </>
  );
};

export default UserCollections;
