import axios from 'axios';

interface Inscription {
  collection: any;
  content_type: string;
  escrow: any;
  id: string;
  meta: any;
  num: number;
  outpoint: {
    outpoint: string;
    sat_offset: number;
    sats: number;
  };
  satributes: any[];
}

interface WalletResponse {
  balance: number;
  brc20: any[];
  confirmed_balance: number;
  frozen_balance: number;
  inscription_balance: number;
  inscriptions: Inscription[];
  unconfirmed_balance: number;
  utxo_count: number;
}

class TurboWalletClient {
  private baseUrl: string;

  constructor() {
    this.baseUrl = 'https://turbo.ordinalswallet.com/wallet';
  }

  async getWalletDetails(walletAddress: string): Promise<WalletResponse> {
    try {
      const response = await axios.get<WalletResponse>(`${this.baseUrl}/${walletAddress}`);
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch wallet details');
    }
  }
}

export default TurboWalletClient;