import React, { useRef, useEffect } from "react";

interface AssetPaths {
  [key: string]: string[];
}

const assets: AssetPaths = {
  eyes: ["pieces/eyes/blue.png", "pieces/eyes/green.png", "pieces/eyes/red_blue.png"],
  beaks: ["pieces/beaks/blue.png", "pieces/beaks/pink.png"],
  skinColors: ["pieces/skin/pink.png", "pieces/skin/white.png"],
};

const PixelArtGenerator: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const loadAsset = (assetPath: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = assetPath;
    });
  };

  const drawAsset = async (category: string) => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        const assetPath = assets[category][Math.floor(Math.random() * assets[category].length)];
        const img = await loadAsset(assetPath);
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      }
    }
  };

  const generatePixelArt = async () => {
    await drawAsset("skinColors");
    await drawAsset("eyes");
    await drawAsset("beaks");
  };

  const downloadImage = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
      const link = document.createElement("a");
      link.download = "pixel-art.png";
      link.href = image;
      link.click();
    }
  };

  useEffect(() => {
    generatePixelArt();
  }, []);

  return (
    <div>
      <canvas
        ref={canvasRef}
        width="32"
        height="32"
        style={{
          width: "256px", // Scale up the canvas display size. Adjust as needed.
          height: "256px", // Scale up the canvas display size. Adjust as needed.
          imageRendering: "pixelated", // Ensure the upscaled image remains pixelated.
        }}
      ></canvas>
      <button onClick={downloadImage} style={{ color: "#000" }}>
        Download
      </button>
    </div>
  );
};

export default PixelArtGenerator;
