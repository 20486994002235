import { Box, Flex, Text, Image, Center, Link } from "@chakra-ui/react";
import GooseGrid from "../components/GeeseGrid";
import { FaTwitter } from "react-icons/fa";
import MagicEdenLogo from "../assets/icons/MagicEdenLogo.svg";
import { useFetchMempoolData } from "../hooks/useFetchMempoolData";
import CountdownCard from "../components/CountdownCard";
import { Link as RouterLink } from "react-router-dom";

const LandingPage = () => {
  const { blockTipHeight, estimatedHalvingDate } = useFetchMempoolData();

  return (
    <Flex
      textAlign={"center"}
      pt={10}
      justifyContent={"center"}
      direction={"column"}
      width={"full"}
      overflow={"hidden"}
    >
      <Box width={{ base: "full", sm: "lg", lg: "xl" }} margin={"auto"}>
        <Center>
          <Image src={`./illustrations/OG.png`} width={450} style={{ imageRendering: "pixelated" }} />
        </Center>

        <Box
          style={{
            backgroundColor: "#fff",
            boxShadow: "0 4px 6px rgba(0,0,0,0.3), 0 1px 3px rgba(0,0,0,0.08)",
            borderRadius: 5,
            padding: 20,
          }}
          width={{ base: "full", sm: "lg", lg: "lg" }}
          marginLeft={"auto"}
          marginRight={"auto"}
          marginTop={5}
          marginBottom={5}
        >
          <Text fontSize="2xl">Ordinals Pixel Art Collection Inscribed On Bitcoin</Text>
          <Text>February 2023</Text>

          <Box display="flex" justifyContent="center" alignItems="center" marginTop={5}>
            <Link href={`https://twitter.com/OrdinalGeese`} isExternal>
              <FaTwitter size={30} style={{ cursor: "pointer", margin: 10 }} />
            </Link>
            <Link href={`https://magiceden.io/ordinals/marketplace/ordinal_geese`} isExternal>
              <Image src={MagicEdenLogo} alt="Magic Eden" width={31} style={{ cursor: "pointer", margin: 10 }} />
            </Link>
          </Box>
        </Box>

        <Box
          style={{
            backgroundColor: "#fff",
            boxShadow: "0 4px 6px rgba(0,0,0,0.3), 0 1px 3px rgba(0,0,0,0.08)",
            borderRadius: 5,
            padding: 20,
          }}
          width={{ base: "full", sm: "lg", lg: "lg" }}
          marginLeft={"auto"}
          marginRight={"auto"}
          marginTop={5}
          marginBottom={5}
        >
          {blockTipHeight && blockTipHeight < 840000 ? (
            <>
              <Text fontSize="2xl">OG Rune Coins</Text>
              <Text>
                Coming Soon <CountdownCard targetDate={estimatedHalvingDate || new Date()} />
              </Text>
              <Text fontSize="1xl">
                Estimated Halving Date: {estimatedHalvingDate ? estimatedHalvingDate.toLocaleString("en-US") : "N/A"}
              </Text>
            </>
          ) : (
            <>
              <Text fontSize="2xl">OG Rune Coins</Text>
              <Text style={{ textDecoration: "underline" }}>
                <RouterLink to="/runes">Claim Now!</RouterLink>
              </Text>
            </>
          )}
        </Box>
      </Box>

      <Box width={{ base: "full", sm: "100%", md: "100%", lg: "60%" }} margin={"auto"} marginBottom={10}>
        <GooseGrid />
      </Box>
    </Flex>
  );
};

export default LandingPage;
