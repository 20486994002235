import PixelArtGenerator from "../components/PixelArtGenerator";
import SignMessageButton from "../components/SignMessageButton";
import { Network, OrdConnectProvider, OrdConnectKit } from "@ordzaar/ord-connect";
import { Box, Center, Flex, Image, Link, Text } from "@chakra-ui/react";
import { FaTwitter } from "react-icons/fa";
import UserCollections from "../components/UserCollections";

const MintPage = () => {
  return (
    <OrdConnectProvider initialNetwork={Network.MAINNET}>
      <Box bg="#1b202b" w="100%" p={4} color="white" textAlign="right">
        <OrdConnectKit />
      </Box>

      <Flex
        textAlign={"center"}
        pt={10}
        justifyContent={"center"}
        direction={"column"}
        width={"full"}
        overflow={"hidden"}
      >
        <Box width={{ base: "full", sm: "lg", lg: "xl" }} margin={"auto"}>
          <Center>
            <Image src={`./illustrations/OG.png`} width={450} style={{ imageRendering: "pixelated" }} />
          </Center>

          <Box
            style={{
              backgroundColor: "#fff",
              boxShadow: "0 4px 6px rgba(0,0,0,0.3), 0 1px 3px rgba(0,0,0,0.08)",
              borderRadius: 5,
              padding: 20,
            }}
            width={{ base: "full", sm: "lg", lg: "lg" }}
            marginLeft={"auto"}
            marginRight={"auto"}
            marginTop={5}
            marginBottom={5}
          >
            <Text fontSize="2xl">Gooslings Mint</Text>
            <Text>Dynamically Generated Gooslings Based on Collections You Own</Text>

            <Box display="flex" justifyContent="center" alignItems="center" marginTop={5}>
              <Link href={`https://twitter.com/OrdinalGeese`} isExternal>
                <FaTwitter size={30} style={{ cursor: "pointer", margin: 10 }} />
              </Link>

              <UserCollections />
            </Box>
          </Box>
        </Box>
      </Flex>
    </OrdConnectProvider>
  );
};

export default MintPage;
