import PixelArtGenerator from "../components/PixelArtGenerator";
import SignMessageButton from "../components/SignMessageButton";
import { Network, OrdConnectProvider, OrdConnectKit } from "@ordzaar/ord-connect";
import { Box } from "@chakra-ui/react";

const ExperimentPage = () => {
  return (
    <>
      <Box bg="#1b202b" w="100%" p={4} color="white" textAlign="right">
        <OrdConnectProvider initialNetwork={Network.MAINNET}>
          <OrdConnectKit />
          <SignMessageButton />
        </OrdConnectProvider>
      </Box>

      <div>
        <h1 style={{ color: "#000" }}>Experiment Page</h1>
        <text>test</text>

        <PixelArtGenerator />
      </div>
    </>
  );
};

export default ExperimentPage;
