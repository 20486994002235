import { useSignMessage, useOrdConnect } from "@ordzaar/ord-connect";
import { Button } from "@chakra-ui/react";
import { Verifier } from "bip322-js";

const SignMessageButton = () => {
  const { signMsg, isLoading, error } = useSignMessage();
  const { address } = useOrdConnect(); // Assuming `address` indicates connection status

  const handleSignMessage = async () => {
    // Assuming `address.payments` or `address.ordinals` can be used to sign the message
    // Adjust according to the actual API
    const walletAddress = address.ordinals || address.payments;
    if (!walletAddress) {
      console.error("No wallet address found.");
      return;
    }
    const signedMessage = await signMsg(walletAddress, "hello");
    console.log(signedMessage);

    if (signedMessage) {
      const validity = Verifier.verifySignature(walletAddress, "hello", signedMessage);
      console.log("signature valid: ", validity); // True
    }
  };

  // Only render the button if there's a connected wallet address
  if (!address.payments && !address.ordinals) {
    return null;
  }

  return (
    <>
      <Button onClick={handleSignMessage} isLoading={isLoading} disabled={isLoading}>
        Sign Message
      </Button>
      {error && <p>Error: {error}</p>}
    </>
  );
};

export default SignMessageButton;
